<template>
  <div class="main__content presentation">
    <div class="presentation__section">
      <h5
        class="presentation__field-title"
        :class="{ required_el: !isEditPresentation }"
      >
        {{ $t('main.presentation_name') }}
      </h5>
      <text-field v-model="name" :is-error="$v.name.$error" />
    </div>
    <div class="presentation__section">
      <h4
        class="title-h4 presentation__title"
        :class="{ required_el: !isEditPresentation }"
      >
        {{ $t('main.import_presentation') }}
      </h4>
      <FileUpload
        v-model="file"
        :max-size-k-b="fileMaxSize"
        :valid-formats="validFormats"
        :is-error="$v.file.$error"
        class="presentation__file-upload"
      />
    </div>

    <div class="presentation__buttons-block">
      <template v-if="isEditPresentation">
        <Button
          transparent
          class="presentation__button"
          @click.native="
            $router.push({ name: 'content-administrator_presentations-list' })
          "
        >
          {{ $t('buttons.cancel') }}
        </Button>
        <Button class="presentation__button" @click.native="savePresentation"
          >{{ $t('buttons.make_improvement') }}
        </Button>
      </template>
      <template v-else>
        <Button
          class="presentation__button"
          icon-name="arrow"
          isRightIcon
          @click.native="savePresentation"
          >{{ $t('buttons.create') }}
        </Button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { createFile } from '@/library/helpers';
import FileUpload from '@/elements/FileUpload.vue';
export default {
  components: { FileUpload },

  data() {
    return {
      name: '',
      file: null,
      validFormats: ['pdf'],
      fileMaxSize: 5000,
    };
  },

  async created() {
    !!!this.$route.params.activity &&
      this.$router.push({ name: 'content-administrator_presentations-list' });

    if (this.isEditPresentation) {
      await this.$store.dispatch(
        'getPresentationContentAdmin',
        this.$route.params.id
      );
      await this.getCurrentPresentation();
    }
  },

  computed: {
    ...mapGetters(['getCurrentPresentationAdmin']),

    isEditPresentation() {
      return this.$route.params.activity === 'edit';
    },

    getDataForSave() {
      let data = new FormData();
      !!this.name.length && data.append('title', this.name);
      !!this.file?.length && data.append('attachment', this.file[0]);
      !!this.$route.params?.id && data.append('uuid', this.$route.params.id);
      return data;
    },
  },

  methods: {
    async getCurrentPresentation() {
      this.name = this.getCurrentPresentationAdmin.title;
      this.file = [
        await createFile(
          this.getCurrentPresentationAdmin.attachment.original_url,
          this.getCurrentPresentationAdmin.attachment.mime
        ),
      ];
    },

    savePresentation() {
      if (this.isEditPresentation) {
        this.$store.dispatch(
          'updatePresentationContentAdmin',
          this.getDataForSave
        );
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.$store.dispatch(
            'createPresentationContentAdmin',
            this.getDataForSave
          );
        }
      }
    },
  },

  validations: {
    name: {
      required,
    },
    file: {
      required,
    },
  },
};
</script>

<style lang="sass" scoped>
.presentation__section
  margin-bottom: 10rem
.presentation__field-title
  color: $draft
  font-size: 16px
  font-weight: 300
  margin-bottom: 10px
  margin-top: 50px
.presentation__title
  margin-bottom: 40px
  @include xxs
    line-height: 24px

.presentation__file-upload
  width: 30%
  min-width: 350px
  @include xxs
    min-width: 100%
.presentation__buttons-block
  display: flex
  justify-content: flex-end
  padding-bottom: 50px
  @include xs
    justify-content: center
  @include xxs
    flex-direction: column
    align-items: center

.presentation__button
  @include xs
    min-width: 220px
  @include xxs
    min-width: 290px
  &:first-child
    margin-right: 20px
    @include xxs
      margin: 0 0 20px 0
</style>
